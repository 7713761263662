<template>
  <ProductsCatalog v-if="isMounted" key="catalog" />
  <Transition name="wrapper">
    <div v-if="!isMounted" key="stub" style="pointer-events: none;">
      <!-- Заглушка -->
      <CommonSearchBar />
      <div class="main-inner" style="height: 75vh">
        <Loader />
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { useAuthStore } from "~/store/auth";
import { useCityStore } from "~/store/cities";
import { useErrorStore } from "~/store/error";
import { useModalStore } from "~/store/modal";
import { usePartnerStore } from "~/store/partners";
import { useProfileStore } from "~/store/profile";

const route = useRoute();

const store = {
  auth: useAuthStore(),
  city: useCityStore(),
  error: useErrorStore(),
  modal: useModalStore(),
  partner: usePartnerStore(),
  profile: useProfileStore(),
};

onBeforeMount(async () => {
  const { getTokens } = store.auth;
  const { fetchUser, loadAddressFromLocalStorage } = store.profile;
  const { fetchCities } = store.city;

  Promise.all([getTokens(), fetchUser(), loadAddressFromLocalStorage()]);
  // cities грузим последними
  await fetchCities();
});

// инициируем пользовательскую ошибку
const isMounted = ref<boolean>(false);
const { city, confirmed } = storeToRefs(store.city);

onMounted(async () => {
  const { fetchCityByCoords, loadCityFromLocalStorage } = store.city;
  const { fetchPartner } = store.partner;

  await loadCityFromLocalStorage();

  if (!confirmed.value) {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          await fetchCityByCoords(latitude, longitude);
        }
        /*
        (error) => {
          if (error) {
            store.error.setCustomError(
              `Ошибка при получении местоположения: ${error.message}`,
              403
            );
          }
        }
        */
      );
    } else {
      store.error.setCustomError("Геолокация не поддерживается вашим браузером.");
    }
  }

  if (!city.value?.id) {
    store.modal.openModal("city");
  }

  const { partner } = route.query;
  if (partner) {
    await fetchPartner(String(partner))
  }

  await new Promise((resolve) => setTimeout(resolve, 600));
  isMounted.value = true;
});

watch(
  () => city.value,
  async (city) => {
    if (city?.id) {
      await store.partner.fetchPartners()
    }
  },
  { immediate: true }
);
</script>

<style>
.wrapper-enter-active {
  transition: opacity 250ms linear;
}

.wrapper-leave-to,
.wrapper-leave-active {
  transition: none;
}

.wrapper-enter-from {
  opacity: 0;
}
</style>